/* eslint-disable */
import React, { useState } from "react";
import "./_widgetMenu.scss";
import { AiFillCaretUp } from "react-icons/ai";
import { AiOutlineArrowRight } from "react-icons/ai";
export default function WidgetMenu() {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="notification-icon-container">

     {!isOpen? <img src={require("../../../assets/001.svg")} onClick={() => {
        setIsOpen(!isOpen)
      }} style={{ 'width': '40px', height: "40px" }} />
      :<img src={require("../../../assets/Z icon.svg")} onClick={() => {
        setIsOpen(!isOpen)
      }} style={{ 'width': '40px', height: "40px" }}
      />
    }  
      {isOpen && (
        <div className="">
          <div className=" text-right d-block"><AiFillCaretUp className="svg" /></div>
          <div
            className="notification-overlay"
            onClick={() => setIsOpen(false)}
          />
          <div className="notifications-container">
            <ul className="notification-wrapper">
              <li className="notification">
                <div className="relative"> <a href="https://www.zigram.tech/" target="_blank"><img className="logo my-3" src={require('../../../assets/Landing page icons/Group 320.svg')} /></a>
                  <AiOutlineArrowRight className="fa-arrow-right text-right zigram-arrow" /></div>
                <p className="notification-text mb-3">Regulatory Technology (RegTech) Organization with a focus on AML, FCC and emerging risk</p>
              </li>
              <li  className="notification">
              <div className="relative"> <a href="https://www.prescreening.io/" target="_blank"><img className="logo-product my-3" src={require('../../../assets/Landing page icons/PSIO Main.svg')}/> </a>
             <AiOutlineArrowRight className="fa-arrow-right text-right psio-arrow"/></div> 
              <p className="notification-text mb-3">Quick Scan To Identify Customer & Third-Party Risk</p>
                </li>
              <li className="notification">
                <div className="relative"> <a href="https://www.amlpenalties.com/" target="_blank"><img className="logo-product my-3" src={require('../../../assets/Landing page icons/AML Widget.svg')} /></a>
                  <AiOutlineArrowRight className="fa-arrow-right text-right aml-arrow" /></div>
                <p className="notification-text mb-3">The Most Extensive AML Data Platform, for Professionals, Organizations & Regulators</p>
              </li>
              <li className="notification">
                <div className="relative"> <a href="https://www.dragnetalpha.com/" target="_blank"> <img className="logo-product my-3" src={require('../../../assets/Landing page icons/DA Widget.svg')} /></a>
                  <AiOutlineArrowRight className="fa-arrow-right text-right DA-arrow" /></div>
                <p className="notification-text mb-3">Intelligent Online Content Monitoring SaaS Platform for Enterprises</p>
              </li>
              <li className="notification">
                <div className="relative"> <a href="https://www.dataassetbuilder.com/" target="_blank"><img className="logo-product my-3" src={require('../../../assets/Landing page icons/DAB Widget.svg')} /></a>
                  <AiOutlineArrowRight className="fa-arrow-right text-right DAB-arrow" /></div>
                <p className="notification-text mb-3">Most Advanced PEP Data Asset focused machine learning and automation platform for large teams</p>
              </li>
              <li className="notification">
                <div className="relative"> <a href="https://www.duediliger.com/" target="_blank"><img className="logo-product my-3" src={require('../../../assets/Landing page icons/DueDiliger Widget.png')} /></a>
                  <AiOutlineArrowRight className="fa-arrow-right text-right DueDiliger-arrow" /></div>
                <p className="notification-text mb-3">Global research based due diligence to determine potential red flags</p>
              </li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
}

