export const DASHBOARD = '/dashboard';
export const EVENTS = '/events';
export const COUNTRY = '/country';
export const SEARCH = '/search';
export const AdvancedSearch = '/global-search';
export const COMING_SOON = '/coming-soon';
export const RESOURCES = '/resources';
export const ABOUT = '/about';
export const USER_PROFILE = '/profile';
export const EVENT_DETAILS = '/event-details';
export const LINKEDIN = '/linkedin';
export const PRIVACY_POLICY = '/privacy-policy';
export const DISCLAIMER = '/disclaimer';
export const ADMIN = '/admin';
export const TERMS = '/terms';
export const ANALYTICS = '/analytics';
export const SIGNUP = "/signup"
export const LOGIN = "/login"

export const HOME_PAGE = '/';

export const APIDOC = "/api-doc"
export const DISCLAIMER_FROM_HOME = "/disclaimer"
export const PRIVACY_POLICY_PAGE = "/privacy-policy"
export const TERMS_PAGE = "/terms-and-conditions"
export const NOT_FOUND='/404'
export const BLOG_LIST='/blog-list'
export const CONTACT_US = "/contact-us"
export const BLOG ="/blog"
